.main {
  position: relative;
  display: grid;
  gap: 1rem;
  justify-content: center;
  place-content: center;
  justify-items: center;
  overflow: auto;
  flex-grow: 1;
}

.tabContainer {
  width: 100%;
  max-width: 500px;
  padding-inline: 2rem;
}

.tabSelect {
  display: flex;
  justify-content: stretch;
  gap: 0.125rem;
  padding: 0.125rem;
  margin: 0 auto 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.tabSelect > * {
  width: 100%;
}

.tabContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
  padding: 3rem 2rem 2rem;
  margin: 1rem;
  border-radius: 0.625rem;
  box-shadow: 7px 0 20px #cfd5de;
  width: 30rem;
}

.startButton {
  height: 3.125rem;
  font-size: 1.125rem !important;
  border-radius: 0.625rem;
  color: #ffffff;
  background-color: #40cc52 !important;
  border: none;
  cursor: pointer;
  box-shadow: -8px -8px 8px #fff, 5px 5px 8px #cbd9f0;
}

.passwordInput {
  width: 100%;
  color: #000000;
  height: 3.125rem;
  border-radius: 0.625rem;
  font-size: 1rem;
  padding: 0.75rem;
  background: inherit;
  border: 2px solid #d0daea;
  outline: none !important;
}

@media screen and (max-width: 480px) {
  .tabContent {
    width: auto;
  }
}
